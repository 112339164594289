@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "urbanist-bold";
  src: url("./assets/fonts/Urbanist-Bold.ttf");
}
@font-face {
  font-family: "urbanist-regular";
  src: url("./assets/fonts/Urbanist-Regular.ttf");
}

body {
  margin: 0;
  font-family: "urbanist-regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
